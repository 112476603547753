import gql from "graphql-tag";
import {SUBSCRIPTIONS_FIELDS} from "@/modules/subscription/graphql/Fragments";

export const SUBSCRIPTION_STATUS = gql`
    mutation SubscriptionStatus($input:SubscriptionInput!) {
        subscription_status(subscription:$input) {
            ...SubscriptionFields
        }
    }
    ${SUBSCRIPTIONS_FIELDS}
`;

export const ADD_SUBSCRIPTION_PRODUCT = gql`
    mutation addSubscriptionProduct($product_id: Int!, $quantity: Int!, $multiple:Boolean!, $order_subscription_id:Int!) {
        subscription_product(product_id:$product_id, quantity:$quantity, multiple:$multiple, order_subscription_id: $order_subscription_id )
    }
`;

export const UPDATE_SUBSCRIPTION_PRODUCT = gql`
    mutation UpdateSubscriptionProduct($product_id: Int!, $quantity: Int!, $multiple:Boolean!, $order_subscription_id:Int!) {
        update_subscription_product(product_id:$product_id, quantity:$quantity, multiple:$multiple, order_subscription_id: $order_subscription_id)
    }
`;

export const DELETE_SUBSCRIPTION_PRODUCT = gql`
    mutation DeleteSubscriptionProduct($product_id: Int!, $order_subscription_id:Int!) {
        delete_subscription_product(product_id:$product_id, order_subscription_id: $order_subscription_id )
    }
`;