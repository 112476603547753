import {gql} from "graphql-tag";
import {SUBSCRIPTIONS_FIELDS, PAGINATION, SUBSCRIPTION_DETAIL_FIELDS} from "@/modules/subscription/graphql/Fragments";

export const GET_ORDER_SUBSCRIPTIONS = gql`
    query GetOrderSubscriptions($page:Int, $limit:Int) {
        order_subscriptions(limit:$limit,page:$page) {
            ...Pagination
            data {
                ...SubscriptionFields
            }
        }
    }
    ${SUBSCRIPTIONS_FIELDS}
    ${PAGINATION}
`;

export const SEARCH_ORDER_SUBSCRIPTIONS = gql`
    query SearchOrderSubscriptions($page:Int, $limit:Int,$filter:String,$search_key:String, $type:String , $date_from:String , $date_to:String) {
        search_order_subscriptions(limit:$limit,page:$page,filter:$filter,search_key:$search_key, type:$type , date_from:$date_from , date_to:$date_to){
            ...Pagination
            data {
                ...SubscriptionFields
            }
        }
    }   
    ${SUBSCRIPTIONS_FIELDS}
    ${PAGINATION}
`;

export const ORDER_SUBSCRIPTION_DETAILS = gql`
    query OrderSubscriptionDetails($uuid:String) {
        order_subscription_details(uuid:$uuid)
    }
`;

export const SITE_PRODUCTS = gql`
    query SiteProducts($order_subscription_id:Int!) {
        site_products(order_subscription_id:$order_subscription_id)
    }
`;

export default {GET_ORDER_SUBSCRIPTIONS, SEARCH_ORDER_SUBSCRIPTIONS, ORDER_SUBSCRIPTION_DETAILS}