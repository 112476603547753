
import { defineComponent, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { Apollo, Notify } from "@/core/services";
import { GET_ORDER_SUBSCRIPTIONS, SEARCH_ORDER_SUBSCRIPTIONS } from "@/modules/subscription/graphql/Queries";
import Search from "@/components/search/Search.vue";
import { useI18n } from "vue-i18n";
import { SUBSCRIPTION_STATUS } from "@/modules/subscription/graphql/Mutations";
import { useRouter } from "vue-router";
import Table from "../../../components/Table/Table.vue";
import { setPageHistory } from "@/core/helpers/toolbar";
import { dropdown_handler } from "../../../core/helpers/dropdownHandler";
// import InnerLoader from "../../../components/InnerLoader.vue";

export default defineComponent({
	name: "subscription list",
	components: {
		Search,
		Table,
		// InnerLoader,
	},
	setup() {
		const store = useStore();
		const i18n = useI18n();
		const loader = ref(false);
		const loading = ref(false);
		const subscriptions = ref({}) as Record<any, any>;
		const clickHandler = ref() as Record<any, any>;
		const pagination = ref({}) as Record<any, any>;
		const currentPage = ref();
		const router = useRouter();
		const permissions = store.getters.getPermissions;
		const activeIndex = ref(-1);

		const columns = ref([
			{
				label: "message.CUSTOMER_NAME",
				key: "name",
			},
			{
				label: "message.PAYMENT_TYPE",
				key: "payment_type",
			},
			{
				label: "message.SUBSCRIPTION_INTERVAL",
				key: "tenure",
			},
			{
				label: "message.STATUS",
				key: "status",
			},
			{
				label: "message.INITIAL_DATE",
				key: "initial_order_date",
			},
			{
				label: "message.ACTIONS",
				key: "actions",
				textAlignment: "center",
			},
		]);
		const filterObject = ref([
			{
				label: "message.CUSTOMER",
				value: "name",
			},
		]);
		const appInstance = store.getters.appInstance;

		const handleCurrentChange = (page: number) => {
			clickHandler.value(page);
		};

		const dropdownHandler = (index, id) => {
			activeIndex.value = index === id ? null : id;
		};
		const handleClickOutside = (event) => {
			if (!event.target.closest(".action-btn")) {
				activeIndex.value = -1;
			}
		};

		const get_subscriptions = (page = 0, reload = false) => {
			loader.value = true;
			Apollo.watchQuery({
				query: GET_ORDER_SUBSCRIPTIONS,
				fetchPolicy: "network-only",
				nextFetchPolicy: "cache-only",
				errorPolicy: "all",
				variables: {
					page: page,
					limit: 10,
				},
			}).subscribe(({ data, errors }) => {
				if (errors) {
					Notify.error("message.SOMETHING_WENT_WRONG");
				}
				loader.value = false;
				reload == true ? (currentPage.value = 1) : false;
				subscriptions.value = data.order_subscriptions.data;
				pagination.value = data.order_subscriptions;
				clickHandler.value = get_subscriptions;
			});
		};

		const searchHandler = (page = 0) => {
			loader.value = true;
			const searchData = store.getters.getSearchData;
			Apollo.watchQuery({
				query: SEARCH_ORDER_SUBSCRIPTIONS,
				fetchPolicy: "network-only",
				nextFetchPolicy: "cache-only",
				errorPolicy: "all",
				variables: {
					page: page,
					limit: 10,
					filter: searchData.data.select,
					search_key: searchData.data.input,
				},
			}).subscribe(({ data, errors }) => {
				if (errors) {
					Notify.error("message.SOMETHING_WENT_WRONG");
				}
				loader.value = false;
				subscriptions.value = data.search_order_subscriptions.data;
				pagination.value = data.search_order_subscriptions;
				clickHandler.value = searchHandler;
			});
		};

		const exportFile = (data) => {
			const searchData = store.getters.getSearchData;
			loading.value = true;
			const sub = Apollo.watchQuery({
				query: SEARCH_ORDER_SUBSCRIPTIONS,
				errorPolicy: "all",
				fetchPolicy: "network-only",
				variables: {
					page: 0,
					limit: 1,
					filter: searchData?.data?.select ? searchData?.data?.select : "",
					search_key: searchData?.data?.input ? searchData?.data?.input : "",
					type: data?.type,
					date_from: data?.dateFrom,
					date_to: data?.dateTo,
				},
			}).subscribe(({ data, errors }) => {
				if (errors) {
					loading.value = false;
				} else if (data) {
					window.open(data?.search_order_subscriptions?.data[0]?.path, "_blank");
					loading.value = false;
				}
				loading.value = false;
				sub.unsubscribe();
			});
		};

		const handleView = (subscription: Record<any, any>) => {
			const searchData = store.getters.getSearchData;
			setPageHistory({
				filter: searchData?.data?.select,
				search_key: searchData?.data?.input,
				page_number: pagination.value?.current_page,
			});
			router.push({
				name: "subscription_detail",
				params: { uuid: subscription.uuid },
			});
		};

		const handleToggle = (subscription: Record<any, any>) => {
			appInstance.$messageBox
				.confirm(`${i18n.t("message.ARE_YOU_SURE_TO_CHANGE_SUBSCRIPTION_STATUS")}?`, i18n.t("message.INFO"), {
					confirmTextButton: i18n.t("message.OK"),
					cancelTextButton: i18n.t("message.CANCEL"),
					type: "info",
				})
				.then(async () => {
					loader.value = true;
					const formData = {
						id: subscription.id,
						status: subscription.status == 1 ? 0 : 1,
					};
					await Apollo.mutate({
						mutation: SUBSCRIPTION_STATUS,
						variables: { input: formData },
						update: (store, { data: { subscription_status } }) => {
							loader.value = false;
							Notify.success(`${i18n.t("message.RECORD_UPDATED_SUCCESSFULLY")}`);
						},
					});
				});
		};

		onMounted(() => {
			const pageHistory = store.getters.getPageHistory;
			document.addEventListener("click", handleClickOutside);

			if (pageHistory?.filter != undefined || pageHistory?.search_key != undefined) {
				searchHandler(pageHistory.page_number);
			} else {
				pageHistory.page_number == undefined ? get_subscriptions(0) : get_subscriptions(pageHistory.page_number);
			}
		});

		return {
			pagination,
			subscriptions,
			currentPage,
			filterObject,
			columns,
			permissions,
			loading,
			loader,
			exportFile,
			get_subscriptions,
			handleCurrentChange,
			searchHandler,
			handleView,
			handleToggle,
			activeIndex,
			dropdownHandler,
			handleClickOutside,
		};
	},
});
