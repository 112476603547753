import {gql} from "graphql-tag";

export const SUBSCRIPTIONS_FIELDS = gql`
    fragment SubscriptionFields on OrderSubscription {
          id
          uuid
          path
          customer {
            user_id
            user {
              name
            }
          }
          order_discounts {
            order_id
            discount_id
            discount {
              code
              discount_type
            }
          }
          payment_type
          tenure
          initial_order_date
          status
    }
`;

export const SUBSCRIPTION_DETAIL_FIELDS = gql`
    fragment SubscriptionDetailFields on OrderSubscription {
        tenure
        status
        initial_order_date
        subscription_id
        next_invoice
        payment_type
        order_id
        order {
          name
          email
          site_id
          site {
            prices_with_tax
          }
          order_products {
            price
            quantity
            total
            price_label
            total_with_tax
            price_label_without_tax
            product_id
            product {
              id
              description {
                name
              }
              media {
                file_path
              }
            }
          }
          order_billing_address {
            phone_number
            address_1
          }
        }
        order_subscription_history {
          order_date
          current_order_id
          current_order {
            id
            uuid
            order_total {
              code
              value
              price
            }
            order_status {
              id
              status
            }
            order_payment {
              payment_method_id
            }
            economic_order_id
            order_status_id
            created_at
          }
        }
    }
`;

export const PAGINATION = gql`
    fragment Pagination on OrderSubscriptionPagination {
        total
        per_page
        current_page
        from
        to
        last_page
        has_more_pages
    }
`;