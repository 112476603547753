
import SubscriptionList from "@/modules/subscription/components/SubscriptionList.vue";
import { defineComponent } from "vue";
import { setPageHeader } from "@/core/helpers/toolbar";
export default defineComponent({
	name: "Subscription List",
	components: {
		SubscriptionList,
	},
	setup() {
		setPageHeader({
			title: "message.SUBSCRIPTIONS_ORDERS",
			actionButton: null,
			breadCrumbs: [{ name: "message.HOME", link: "/dashboard" }, { name: "message.SUBSCRIPTIONS", link: "#" }, { name: "message.SUBSCRIPTIONS_ORDERS" }],
		});
	},
});
